<template>
  <div class="login-head">
    <span @click="home"><v-img :default-img="require('@/assets/img/homelogo.png')"/></span>
    <p>{{title}}</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VImg from "@/components/VImg";
export default {
  components: {
    VImg,
  },
  props:['title'],
  computed: mapGetters(["tenant"]),
  methods:{
    home(){
      this.$router.push("/");
    }
  }
}
</script>

<style lang="scss" scoped>
.login-head{
  display: flex;
  align-items: center;
  height: 75px;
  padding: 0 24px;
  background: #fff;
  border-bottom: 1px solid #E2E8F0;
  >span{
    cursor: pointer;
    height: 38px;
    display: flex;
    align-items: center;
    >img{
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  >p{
    margin-left: 24px;
    padding-left: 24px;
    font-size: 18px;
    line-height: 24px;
    border-left: 1px solid #f5f5f5;
  }
}
</style>
